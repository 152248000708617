<style lang="less" scoped>
.width-xs {
 width: 180px
}
.content-item {
  background: #fff;
  padding: 0 30px;
  border-radius: 2px;
  .content-item-title {
    padding: 15px 0;
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-item-main {
    padding: 14px 0;
    .item-main-col {
      display: flex;
      .item-main {
        width: 33.3%;
        padding: 10px 0;
        .item-main-avatarUrl {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }
    }
  }
}
.member-btn {
  padding: 0 0 10px 50px;
}
.p-l-50 {
  padding-left: 50px;
}
</style>
<template>
  <div class="page-main">
    <div class="page-content">
      <div class="page-content-item">
        <div style="padding-top: 36px"></div>
        <Form ref="form" :model="formData" :rules="formRule" :label-width="150">
          <div class="content-item">
            <div class="content-item-title">基本信息</div>
            <div class="content-item-main">
              <Row>
                <Col :span="8">
                  <FormItem label="消息ID" prop="id">
                    <Input
                      disabled
                      class="width-xs"
                      v-model="formData.id"
                      placeholder=""
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="14">
                  <FormItem label="消息标题" prop="templateName">
                    <Input
                      v-model="formData.templateName"
                      placeholder=""
                      maxlength="20"
                      show-word-limit
                      :disabled="allDisabled"
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="14">
                  <FormItem label="模板内容" prop="context">
                    <Input
                      v-model="formData.context"
                      placeholder=""
                      type="textarea"
                      maxlength="500"
                      show-word-limit
                      :disabled="allDisabled"
                      :rows="9"
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
              <!--<Row>
                  <Col :span="14">
                      <FormItem label="模板参数" prop="remark">
                        <Input v-model="formData.remark" placeholder=""  type="textarea"
                        maxlength="500" show-word-limit :disabled="allDisabled">
                        </Input>
                      </FormItem>
                    </Col>
                  </Row>-->
              <Row>
                <Col :span="18">
                  <FormItem label="支持推送渠道" prop="channelType">
                    <Radio-group
                      v-model="formData.channelType"
                      @on-change="handleTypeChange"
                    >
                      <!--发送方式：短信SMS,微信WECHAT,站内信INNM,云推送APP,邮箱MAIL,小程序H5-->
                      <Radio
                        :key="key"
                        :label="item.value"
                        :disabled="
                          allDisabled || itemDisabled || item.value == 'SMS'
                        "
                        v-for="(item, key) in MESSAGE_CHANNEL_TYPE"
                        >{{ item.label }}</Radio
                      >
                    </Radio-group>
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
          <div
            class="content-item"
            v-if="
              formData.channelType == 'INNM' || formData.channelType == 'SMS'
            "
          >
            <div class="content-item-title">
              {{ formData.channelType == "INNM" ? "站内信" : "短信" }}
            </div>
            <div class="content-item-main">
              <Row>
                <Col :span="8">
                  <FormItem label="消息类型" prop="points">
                    <Select
                      class="width-xs"
                      v-model="formData.businessType"
                      placeholder="请选择"
                      :disabled="allDisabled || itemDisabled"
                    >
                      <Option
                        v-for="(item, key) in businessTypeList"
                        :value="item.value"
                        :key="key"
                        >{{ item.label }}</Option
                      >
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
          <div class="content-item" v-if="formData.channelType == 'WECHAT'">
            <div class="content-item-title">小程序消息配置</div>
            <div class="content-item-main">
              <Row>
                <Col :span="8">
                  <FormItem label="APPID" prop="channelCode">
                    <Select
                      class="width-xs"
                      v-model="formData.channelCode"
                      placeholder="请选择"
                      :disabled="allDisabled || itemDisabled"
                    >
                      <Option
                        v-for="(item, key) in appIdList"
                        :value="item.channelCode"
                        :key="key"
                        >{{ item.appId }}</Option
                      >
                    </Select>
                  </FormItem>
                </Col>
                <Col :span="8">
                  <FormItem label="模板ID" prop="thirdModelCode">
                    <Input
                      v-model="formData.thirdModelCode"
                      placeholder=""
                      :disabled="allDisabled"
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
          <div class="content-item" v-if="formData.channelType == 'H5'">
            <div class="content-item-title">公众号消息配置</div>
            <div class="content-item-main">
              <Row>
                <Col :span="8">
                  <FormItem label="APPID" prop="channelCode">
                    <Select
                      class="width-xs"
                      v-model="formData.channelCode"
                      placeholder="请选择"
                      :disabled="allDisabled || itemDisabled"
                    >
                      <Option
                        v-for="(item, key) in appIdList"
                        :value="item.channelCode"
                        :key="key"
                        >{{ item.appId }}</Option
                      >
                    </Select>
                  </FormItem>
                </Col>
                <Col :span="8">
                  <FormItem label="模板ID" prop="thirdModelCode">
                    <Input
                      v-model="formData.thirdModelCode"
                      placeholder=""
                      :disabled="allDisabled"
                    >
                    </Input>
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
          <div
            class="content-item"
            v-if="formData.channelType && formData.channelType != 'SMS'"
          >
            <div class="content-item-main">
              <Row>
                <Col :span="8">
                  <FormItem label="操作反馈" prop="picUrl">
                    <sp-linkto
                      v-model="formData.picUrl"
                      :options="linkOptions"
                      :disabled="allDisabled && editMode != 'urledit'"
                    ></sp-linkto>
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>

      <sp-footer>
        <Button
          v-if="pageType === 'add' || pageType === 'edit'"
          type="primary"
          @click="submit"
          :loading="btnLoading"
          >保存</Button
        >
        <Button @click="cancel" style="margin-left: 10px">返回</Button>
      </sp-footer>
    </div>
  </div>
</template>
<script>
  import {
    MESSAGE_CHANNEL_TYPE,
  } from '@/util/enum';

  export default {
    data() {
      let _this = this;
      return {
        MESSAGE_CHANNEL_TYPE,
        btnLoading: false,
        EVENT_TYPE_TASK: ['活动消息', '系统公告'], // '积分消息','订单消息','会员消息',
        linkOptions: ['miniPage', 'otherMiniPage', 'web'],
        formRule: {
          eventType: [
            {
              required: true,
              message: '请选择任务类型',
              trigger: 'change',
              type: 'number',
            },
          ],
          linkTo: [
            {
              required: true,
              message: '请选择跳转页面',
              trigger: 'blur',
            },
          ],
          templateName: [
            {
              required: true,
              message: '请输入模板标题',
              trigger: 'blur',
            },
          ],
          context: [
            {
              required: true,
              message: '请输入模板内容',
              trigger: 'blur',
            },
          ],
          thirdModelCode: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
          ],
          channelCode: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
          ],
          time: [
            {
              required: true,
              message: '请选择任务有效期',
              trigger: 'change',
              type: 'array',
              validator: (rule, val, cb) => {
                let flag = val && val.length && val.every((v) => v);
                // eslint-disable-next-line eqeqeq
                if (!flag && _this.formData.longTerm == false) {
                  cb(new Error('请选择任务有效期'));
                  return;
                }
                cb();
              },
            },
          ],
        },
        formData: {
          businessType: '',
          channelCode: '',
          channelType: 'INNM',
          context: '',
          id: '',
          pfModelCode: '',
          picUrl: '',
          templateName: '',
          type: '',
          thirdModelCode: '',
        },
        businessTypeList: [],
        appIdList: [],
      };
    },
    created() {
      // this.formData.triggerMode = this.triggerMode
      this.formData.pfModelCode = this.$route.query.pfModelCode;
      if (this.$route.query.pfModelCode) {
        this.getDetail();
      }
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.type == 'urledit') {
        this.linkOptions = ['miniPage'];
      }

      this.queryBusinessTypeList();
    },
    watch: {
      'formData.picUrl': function (val) {
        // 'miniPage','otherMiniPage','web'
        if (val.indexOf('#type=miniPage') >= 0) {
          this.formData.type = 2;
        } else if (val.indexOf('#type=otherMiniPage') >= 0) {
          this.formData.type = 4;
        } else if (val.indexOf('#type=web') >= 0) {
          this.formData.type = 5;
        } else {
          this.formData.type = 6; // 无
        }
      },
    },
    computed: {
      editMode() {
        return this.$route.query.type || '';
      },
      itemDisabled() {
        // const data = this.formData
        return this.pageType === 'edit';
      },
      allDisabled() {
        // eslint-disable-next-line eqeqeq
        return this.pageType === 'detail' || this.$route.query.type == 'urledit';
      },
      pageType() {
        let type = 'add';
        // eslint-disable-next-line eqeqeq
        if (this.$route.name == 'messageTemplateDetail') {
          type = 'detail';
        }
        // eslint-disable-next-line eqeqeq
        if (this.$route.name == 'messageTemplateAdd' && !this.$route.query.id) {
          type = 'add';
        // eslint-disable-next-line eqeqeq
        } else if (this.$route.name == 'messageTemplateEdit') {
          type = 'edit';
        }
        return type;
      },
    },
    methods: {
      /**
       * 获取自动任务数据
       */
      getDetail() {
        let data = {
          pfModelCode: this.formData.pfModelCode,
        };
        this.$axios({
          url: this.$api.message.getModel,
          data,
        }).then((d) => {
          if (d.picUrl && d.picUrl.indexOf('#type=') < 0) {
            d.picUrl = '#type=miniPage&url=' + d.picUrl; // 默认小程序
          }
          this.formData = d;
          this.handleTypeChange(d.channelType, true);
        });
      },
      /**
       * 获取自动任务数据
       */
      queryChannelList() {
        let data = { channelType: this.formData.channelType };
        this.$axios({
          url: this.$api.message.queryChannelList,
          data,
        }).then((d) => {
          this.appIdList = d;
        });
      },
      /**
       * 监听任务类型变化
       */
      handleTypeChange(val, init) {
        if (!init) {
          this.formData.thirdModelCode = '';
          this.formData.channelCode = '';
        }

        console.log('handleTypeChange', val);
        // <!--发送方式：短信SMS,微信WECHAT,站内信INNM,云推送APP,邮箱MAIL,小程序H5-->
        // eslint-disable-next-line eqeqeq
        if (val == 'H5' || val == 'WECHAT') {
          this.queryChannelList();
          // eslint-disable-next-line eqeqeq
        } else if (val == 'SMS' || val == 'INNM') {
          // eslint-disable-next-line eqeqeq
          if (this.pageType != 'add') {
            return;
          }
          this.queryBusinessTypeList();
        }
      },

      /**
       * 获取消息类型
       */
      queryBusinessTypeList() {
        let data = {
          subType: this.formData.channelType.toLowerCase(), // "sms",
          type: 'busniess_type',
        };
        this.$axios({
          url: this.$api.message.queryBusinessTypeList,
          data,
        }).then((data) => {
          this.businessTypeList = data || [];
          if (this.pageType === 'add') {
            this.formData.businessType = '5';
          }
        });
      },
      /**
       * 保存
       */
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.addOrUpdate();
          }
        });
      },
      addOrUpdate() {
        let params = this.formData;
        let val = this.formData.channelType;
        let msg = '';
        // eslint-disable-next-line eqeqeq
        if (val == 'H5' || val == 'WECHAT') {
          if (!params.channelCode) {
            msg = '请选择APPID';
          }
          if (!params.thirdModelCode) {
            msg = '请选择模板ID';
          }
          params.businessType = '';
          // eslint-disable-next-line eqeqeq
        } else if (val == 'SMS' || val == 'INNM') {
          if (!params.businessType) {
            msg = '请选择消息类型';
          }
          params.channelCode = '';
          params.thirdModelCode = '';
        }
        if (msg) {
          this.notice({
            type: 'error',
            desc: msg,
          });
          return;
        }

        this.$axios({
          url: this.$api.message.saveModel,
          data: params,
        }).then(() => {
          this.notice({
            type: 'success',
            desc: '保存成功',
          });
          this.cancel();
        });
      },
      /**
       * 返回按钮
       */
      cancel() {
        this.$router.replace({
          name: 'messageTemplate',
        });
      },
    },
  };
</script>